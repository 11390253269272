import React, { Component } from 'react';

class App extends Component {
  componentDidMount() {
    document.title = 'Flamingo Scooters';
    window.location = 'https://flamingoscooters.com/';
  }

  render() {
    return <p>Loading...</p>;
  }
}

export default App;
